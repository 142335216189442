import "./header.scss";
import { Link, NavLink } from "react-router-dom";
import content from "../../Localization/content";

import Logo from "../../assets/images/logo.png";
import Instagram from "../../assets/images/instagram.svg";
import Telegram from "../../assets/images/telegram.svg";
import Facebook from "../../assets/images/facebook.svg";
import Youtube from "../../assets/images/youtube.svg";
import Translate from "../translate-element";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function Header({ lang, setLang }) {
  return (
    <header className="header" id="header">
		<Translate />
      <div className="container">
        <div className="header__box">
          <div className="header__logo-box">
            <Link to={"/"}>
              <img
                className="logo"
                src={Logo}
                alt="logo"
                width={170}
                height={100}
              />
            </Link>
          </div>

          <nav className="header__nav desktop">
            <ul className="header__nav__list">
              <li className="header__nav__item">
                <NavLink className="header__nav__link" to={"/"}>
                  {content[lang].header.welcome}
                </NavLink>
              </li>
              <li className="header__nav__item">
                <NavLink className="header__nav__link" to={"/tours"}>
                  {content[lang].header.tours}
                </NavLink>
              </li>
              <li className="header__nav__item">
                <NavLink className="header__nav__link" to={"/information"}>
                  {content[lang].header.information}
                </NavLink>
              </li>
              <li className="header__nav__item">
                <NavLink className="header__nav__link" to={"/about"}>
                  {content[lang].header.about}
                </NavLink>
              </li>
              <li className="header__nav__item">
                <NavLink className="header__nav__link" to={"/contact"}>
                  {content[lang].header.contact}
                </NavLink>
              </li>
              <li className="header__nav__item">
                <select
                  className="header__select"
                  value={
                    getCookie("googtrans")
                      ? getCookie("googtrans")?.split("/en/")[1]
                      : "en"
                  }
                  onChange={(e) => {
                    document.cookie =
                      "googtrans" +
                      "=" +
                      "" +
                      "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                    document.cookie =
                      "googtrans" +
                      "=" +
                      (e.target.value == "en" ? "" : `/en/${e.target.value}`) +
                      "; expires=Fri, 31 Dec 9999 23:59:59 GMT";
                    window.location.reload();
                  }}
                >
                  <option value="en">English</option>
                  <option value="ru">Russian</option>
                  <option value="uz">Uzbek</option>
                  <option value="fr">France</option>
                  <option value="de">German</option>
                  <option value="it">Italian</option>
                </select>
                {/* <select
									className='header__select'
									defaultValue={lang}
									onChange={(e) => {
										window.localStorage.setItem(
											'lang',
											JSON.stringify(e.target.value),
										);
										setLang(e.target.value);
									}}>
									<option value='en'>English</option>
									<option value='ru'>Russian</option>
									<option value='uz'>Uzbek</option>
									<option value='fr'>France</option>
								</select> */}
              </li>
            </ul>
          </nav>

          <ul className="header__social-list">
            <li>
              <a
                className="header__item__box__link insta"
                href="/"
                target="_blank"
              >
                <img
                  src={Instagram}
                  alt="Instagram icon"
                  width={30}
                  height={30}
                />
              </a>
            </li>

            <li>
              <a
                className="header__item__box__link telegram"
                href="/"
                target="_blank"
              >
                <img
                  src={Telegram}
                  alt="Telegram icon"
                  width={30}
                  height={30}
                />
              </a>
            </li>

            <li>
              <a
                className="header__item__box__link facebook"
                href=""
                target="_blank"
              >
                <img
                  src={Facebook}
                  alt="Facebook icon"
                  width={30}
                  height={30}
                />
              </a>
            </li>

            <li>
              <a
                className="header__item__box__link youtube"
                href="/"
                target="_blank"
              >
                <img src={Youtube} alt="Youtube icon" width={30} height={30} />
              </a>
            </li>
          </ul>

          <input className="checkbox" type="checkbox" />
          <div className="hamburger-lines">
            <span className="line line1"></span>
            <span className="line line2"></span>
            <span className="line line3"></span>
          </div>

          <ul className="header__nav__list mobile-menu">
            <li className="header__nav__item">
              <NavLink className="header__nav__link" to={"/"}>
                {content[lang].header.welcome}
              </NavLink>
            </li>
            <li className="header__nav__item">
              <NavLink className="header__nav__link" to={"/tours"}>
                {content[lang].header.tours}
              </NavLink>
            </li>
            <li className="header__nav__item">
              <NavLink className="header__nav__link" to={"/information"}>
                {content[lang].header.information}
              </NavLink>
            </li>
            <li className="header__nav__item">
              <NavLink className="header__nav__link" to={"/about"}>
                {content[lang].header.about}
              </NavLink>
            </li>
            <li className="header__nav__item">
              <NavLink className="header__nav__link" to={"/contact"}>
                {content[lang].header.contact}
              </NavLink>
            </li>
            <li className="header__nav__item">
              <select
                className="header__select"
                defaultValue={lang}
                onChange={(e) => {
                  window.localStorage.setItem(
                    "lang",
                    JSON.stringify(e.target.value)
                  );
                  setLang(e.target.value);
                }}
              >
                <option value="en">English</option>
                <option value="ru">Russian</option>
                <option value="uz">Uzbek</option>
                <option value="fr">France</option>
              </select>
            </li>

            <li className="header__nav__item">
              <ul className="header__social-list--mobile">
                <li>
                  <a
                    className="header__item__box__link insta"
                    href="/"
                    target="_blank"
                  >
                    <img
                      src={Instagram}
                      alt="Instagram icon"
                      width={30}
                      height={30}
                    />
                  </a>
                </li>

                <li>
                  <a
                    className="header__item__box__link telegram"
                    href="/"
                    target="_blank"
                  >
                    <img
                      src={Telegram}
                      alt="Telegram icon"
                      width={30}
                      height={30}
                    />
                  </a>
                </li>

                <li>
                  <a
                    className="header__item__box__link facebook"
                    href=""
                    target="_blank"
                  >
                    <img
                      src={Facebook}
                      alt="Facebook icon"
                      width={30}
                      height={30}
                    />
                  </a>
                </li>

                <li>
                  <a
                    className="header__item__box__link youtube"
                    href="/"
                    target="_blank"
                  >
                    <img
                      src={Youtube}
                      alt="Youtube icon"
                      width={30}
                      height={30}
                    />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
